html,
body{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}
.container{
  margin: 100px auto 0 auto;
  max-width: 1400px;
}
.nav-container{
  margin: auto
}
.nav-container a {
  color: inherit;
  text-decoration: inherit;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
@media print {
  .no-print, .no-print * {
    display: none !important;
  }
  .container{
    margin-top: 25px;
  }
}